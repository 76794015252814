import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { PortalUserState } from '@trtl/state/user';

export const roleGuard = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  const router = inject(Router);

  const portalUser = store.selectSnapshot(PortalUserState.portalUser);

  const isAuth = route.data.roles.includes(portalUser.role);

  if (!isAuth) {
    return router.navigate(['/']);
  }

  return route.data.roles.includes(portalUser.role);
};
