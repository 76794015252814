import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';

import { GetPortalUserInfo, PortalUserState } from '@trtl/state/user';

export const userGuard = () => {
  const store = inject(Store);

  return store.dispatch(new GetPortalUserInfo()).pipe(
    map(() => !!store.selectSnapshot(PortalUserState.portalUser)),
  );
};
